import request from "@/utils/request";

export function test(data) {
  return request({
    url: "/evaluation/test",
    method: "post",
    data,
  });
}

export function getResultDataAction(data) {
  return request({
    url: "/evaluation/getResultData",
    method: "post",
    data,
  });
}

export function getEvaluateItemByTypeAction(data) {
  return request({
    url: "/evaluation/getEvaluateItemByType",
    method: "post",
    data,
  });
}

export function getUserScoreStatusAction(data) {
  return request({
    url: "/evaluation/getUserScoreStatus",
    method: "post",
    data,
  });
}

export function startUserEvaluteAction(data) {
  return request({
    url: "/evaluation/startUserEvalute",
    method: "post",
    data,
  });
}

export function getEvaluateSelfAction(data) {
  return request({
    url: "/evaluation/getEvaluateSelf",
    method: "post",
    data,
  });
}

export function submitEvaluateBySelfAction(data) {
  return request({
    url: "/evaluation/submitEvaluateBySelf",
    method: "post",
    data,
  });
}

export function getEvaluateEachByTypeAction(data) {
  return request({
    url: "/evaluation/getEvaluateEachByType",
    method: "post",
    data,
  });
}

export function getEvaluateUsersAction(data) {
  return request({
    url: "/evaluation/getEvaluateUsers",
    method: "post",
    data,
  });
}

export function submitEvaluateEachByTypeAction(data) {
  return request({
    url: "/evaluation/submitEvaluateEachByType",
    method: "post",
    data,
  });
}

export function getOrganizationAction(data) {
  return request({
    url: "/evaluation/getOrganization",
    method: "post",
    data,
  });
}

export function getEvaluateItemsAction(data) {
  return request({
    url: "/evaluation/getEvaluateItems",
    method: "post",
    data,
  });
}
