<template>
  <div class="home screen margin-bottom-tab padding-top">
    <div
      class="text-df self margin-lr-sm text-center padding-tb-sm flex justify-center align-center"
      @click="showPopLay"
    >
      <van-icon color="#ee0a24" name="warn-o" />
      <div class="text-df text-bold text-red margin-left-sm">
        评价前请认证阅读年中评价前置说明!
      </div>
    </div>
    <div class="flex align-center justify-between padding-tb padding-lr-sm">
      <div class="title text-lg text-bold">
        {{ result.year }}年-{{ result.stage == 0 ? "年中" : "年终" }}
      </div>
      <div :class="'text-df text-' + getStatusText(result.status).textColor">
        {{ getStatusText(result.status).statusText }}
      </div>
    </div>
    <div
      class="flex align-center padding-tb padding-lr-sm"
      v-if="scoreStatus.evaluate_self == 1"
    >
      <div class="title text-df text-bold">被考评人: {{ userInfo.name }}</div>
    </div>
    <div class="text-df text-gray" v-if="scoreStatus.status == 9">
      本期没有您的评价任务
    </div>
    <div class="text-lg text-green" v-if="scoreStatus.status == 3">
      您已完成本期的评价任务, 感谢您的配合!
    </div>
    <div v-else>
      <div
        class="text-lg text-green"
        v-if="scoreStatus.evaluate_self == 0 && result.status == 1"
      >
        您无需自评, 请在互评阶段开始后再次进入系统!
      </div>
      <div v-else>
        <div v-if="result.status == 1 && scoreStatus.status == 0">
          <van-button type="info" size="normal" @click="startUserEvalute"
            >开始评价</van-button
          >
        </div>

        <div
          v-if="
            (result.status == 1 && scoreStatus.status == 1) ||
            (result.status == 2 && scoreStatus.status == 2)
          "
        >
          <van-sticky>
            <div class="screen">
              <van-tabs v-model="active" class="solid-bottom" @click="onClick">
                <van-tab
                  title="自我评价"
                  v-if="result.status == 1 && scoreStatus.status == 1"
                ></van-tab>
                <van-tab
                  title="工作互评"
                  v-if="result.status == 2 && scoreStatus.status == 2"
                ></van-tab>
                <van-tab
                  title="对管理者评价"
                  v-if="result.status == 2 && scoreStatus.status == 2"
                ></van-tab>
              </van-tabs>
            </div>
          </van-sticky>
          <div
            v-if="active == 0 && result.status == 1 && scoreStatus.status == 1"
          >
            <div class="flex align-center padding-sm padding-top">
              <div class="point bg-red margin-right-xs"></div>
              <div class="title text-df">考核指标</div>
            </div>
            <van-form>
              <div
                class="padding-sm flex align-center flex-direction justify-between"
                v-for="(item, index) in dimensionZero"
                :key="'type_one_a' + index"
              >
                <div
                  class="response flex align-center justify-between margin-bottom-xs"
                >
                  <div class="flex justify-start flex-direction">
                    <div class="title text-df">{{ item.title }}</div>
                  </div>
                  <div class="flex align-center">
                    <van-rate allow-half :size="16" v-model="item.score" />
                    <div class="text-sm score-width">{{ item.score }}</div>
                  </div>
                </div>
                <div class="response text-sm text-left text-gray">
                  {{ item.intro }}
                </div>
                <van-field
                  class="border"
                  v-if="item.need_reason == 1"
                  v-model="item.reason"
                  :rules="[
                    { required: true, message: '请输入' + item.placeholder },
                  ]"
                  rows="2"
                  autosize
                  type="textarea"
                  :placeholder="item.placeholder"
                  @blur="saveEvaluateBySelf"
                />
              </div>
            </van-form>
            <div class="flex align-center padding-sm padding-top">
              <div class="point bg-red margin-right-xs"></div>
              <div class="title text-df">附加</div>
            </div>
            <van-form>
              <div
                class="padding-sm add"
                v-for="(item, index) in dimensionOne"
                :key="'type_one_b' + index"
              >
                <van-cell
                  v-if="item.need_choose == 1"
                  class="padding-lr-0"
                  :border="false"
                  :value="item.choose_user == null ? '选择' : item.choose_user"
                  is-link
                  :title="item.title"
                  @click="choosePerson(item)"
                >
                </van-cell>
                <div
                  v-else
                  class="text-df response text-left padding-bottom-sm"
                >
                  {{ item.title }}
                </div>
                <div class="response text-sm text-left text-gray">
                  {{ item.intro }}
                </div>
                <van-field
                  class="border"
                  v-if="item.need_reason == 1"
                  v-model="item.reason"
                  rows="2"
                  autosize
                  type="textarea"
                  :placeholder="item.placeholder"
                  :rules="[
                    { required: true, message: '请输入' + item.placeholder },
                  ]"
                  @blur="saveEvaluateBySelf"
                />
              </div>
            </van-form>
            <div v-if="invite_user == 1">
              <div class="flex align-center padding-sm padding-top">
                <div class="point bg-red margin-right-xs"></div>
                <div class="title text-df">邀请</div>
              </div>
              <div
                class="padding-sm flex align-center flex-direction justify-between"
              >
                <div
                  class="response flex align-center justify-between margin-bottom-xs"
                >
                  <div class="flex justify-start flex-direction">
                    <div class="title text-df">邀请他人对我评价</div>
                  </div>
                  <van-button
                    round
                    type="info"
                    size="small"
                    @click="assignUsers"
                    >立即邀请</van-button
                  >
                </div>
                <div class="response text-sm text-left text-red">
                  邀请不超过三名同事对自己进行工作互助评价, 不能邀请您的上级!
                </div>
                <div class="response margin-top-sm flex justify-start">
                  <van-tag
                    v-for="(userItem, userIndex) in myAssignUsers"
                    :key="'user' + userIndex"
                    class="margin-right-sm padding-sm"
                    closeable
                    plain
                    size="medium"
                    type="primary"
                    @close="deleteAssignUser(userIndex)"
                  >
                    {{ userItem.name }}
                  </van-tag>
                </div>
              </div>
            </div>
            <van-divider />
            <div>
              <van-button
                type="info"
                size="normal"
                @click="submitEvaluateBySelf"
                >确认提交</van-button
              >
            </div>
          </div>
          <div v-if="result.status * scoreStatus.status == 4">
            <div v-if="active == 0">
              <div
                class="flex flex-direction"
                v-if="evaluateUsersDone.length > 0"
              >
                <div
                  class="top flex justify-between align-center padding-right-sm"
                >
                  <div class="padding-sm text-left text-df margin-top-sm">
                    已完成的评价
                  </div>
                </div>
                <div class="padding-lr-sm">
                  <van-tag
                    v-for="(user_item, index) in evaluateUsersDone"
                    :key="'done' + index"
                    class="margin-rb-sm padding-sm fl"
                    plain
                    size="mini"
                    type="success"
                  >
                    {{ user_item.name }}
                  </van-tag>
                </div>
              </div>
              <div v-if="evaluateUsersToDo.length > 0">
                <div
                  class="top flex justify-between align-center padding-right-sm"
                >
                  <div class="padding-sm text-left text-df">
                    请对他/她进行评价
                  </div>
                </div>
                <van-tabs type="card" color="black" @click="changeEvaluateUser">
                  <van-tab
                    :title="item.name"
                    :name="item.id"
                    v-for="(item, index) in evaluateUsersToDo"
                    :key="'e_user_' + index"
                  ></van-tab>
                </van-tabs>
                <div class="flex align-center padding-sm padding-top">
                  <div class="point bg-red margin-right-xs"></div>
                  <div class="title text-df">考核指标</div>
                </div>
                <van-form>
                  <div
                    class="padding-sm flex align-center flex-direction justify-between"
                    v-for="(item, index) in dimensionZero"
                    :key="'type_one_a' + index"
                  >
                    <div
                      class="response flex align-center justify-between margin-bottom-xs"
                    >
                      <div class="flex justify-start flex-direction">
                        <div class="title text-df">{{ item.title }}</div>
                      </div>
                      <div class="flex align-center">
                        <van-rate allow-half :size="16" v-model="item.score" />
                        <div class="text-sm score-width">{{ item.score }}</div>
                      </div>
                    </div>
                    <div class="response text-sm text-left text-gray">
                      {{ item.intro }}
                    </div>
                    <!-- 这里显示关联的内容 -->
                    <div
                      class="text-df text-grey text-left response padding-top-sm"
                      v-if="item.need_connect == 1"
                    >
                      对方的自我评价
                    </div>
                    <!-- <div class="text-df response">
                      <pre
                        class="text-df text-left"
                        v-if="item.need_connect == 1"
                        >{{ item.userReason }}</pre
                      >
                    </div> -->
                    <!-- <textarea
                      class="text-df text-left response textarea"
                      v-model="item.userReason"
                      v-if="item.need_connect == 1"
                      readonly
                      rows="4"
                      cols="50"
                    ></textarea> -->
                    <van-field
                      class="textarea"
                      v-if="item.need_connect == 1"
                      readonly
                      v-model="item.userReason"
                      autosize
                      label=""
                      type="textarea"
                      placeholder=""
                      @blur="saveEvaluateBySelf"
                    />

                    <van-field
                      class="border"
                      v-if="item.need_reason == 1"
                      v-model="item.reason"
                      :rules="[
                        {
                          required: true,
                          message: '请输入' + item.placeholder,
                        },
                      ]"
                      rows="2"
                      autosize
                      type="textarea"
                      :placeholder="item.placeholder"
                      @blur="saveEvaluateBySelf"
                    />
                  </div>
                </van-form>
                <div class="flex align-center padding-sm padding-top">
                  <div class="point bg-red margin-right-xs"></div>
                  <div class="title text-df">附加</div>
                </div>
                <van-form>
                  <div
                    class="padding-sm add"
                    v-for="(item, index) in dimensionOne"
                    :key="'type_one_b' + index"
                  >
                    <van-cell
                      v-if="item.need_choose == 1"
                      class="padding-lr-0"
                      :border="false"
                      :value="
                        item.choose_user == null ? '选择' : item.choose_user
                      "
                      is-link
                      :title="item.title"
                      @click="choosePerson(item)"
                    >
                    </van-cell>
                    <div
                      v-else
                      class="text-df response text-left padding-bottom-sm"
                    >
                      {{ item.title }}
                    </div>
                    <div class="response text-sm text-left text-gray">
                      {{ item.intro }}
                    </div>
                    <van-field
                      class="border"
                      v-if="item.need_reason == 1"
                      v-model="item.reason"
                      rows="2"
                      autosize
                      type="textarea"
                      :placeholder="item.placeholder"
                      :rules="[
                        {
                          required: true,
                          message: '请输入' + item.placeholder,
                        },
                      ]"
                      @blur="saveEvaluateBySelf"
                    />
                  </div>
                </van-form>
                <van-divider />
                <div>
                  <van-button
                    type="info"
                    size="normal"
                    @click="submitEvaluateEachByType"
                    >确认提交</van-button
                  >
                </div>
              </div>
            </div>
            <div v-if="active == 1">
              <div
                class="flex flex-direction"
                v-if="evaluateUsersDone.length > 0"
              >
                <div
                  class="top flex justify-between align-center padding-right-sm"
                >
                  <div class="padding-sm text-left text-df margin-top-sm">
                    已完成
                  </div>
                </div>
                <div class="padding-lr-sm">
                  <van-tag
                    v-for="(user_item, index) in evaluateUsersDone"
                    :key="'done' + index"
                    class="margin-rb-sm padding-sm fl"
                    plain
                    size="mini"
                    type="success"
                  >
                    {{ user_item.name }}
                  </van-tag>
                </div>
              </div>
              <div v-if="evaluateUsersToDo.length > 0">
                <div
                  class="top flex justify-between align-center padding-right-sm"
                >
                  <div class="padding-sm text-left text-df">
                    请对你的上级管理者进行评价
                  </div>
                </div>
                <van-tabs type="card" color="black">
                  <van-tab
                    :title="item.name"
                    v-for="(item, index) in evaluateUsersToDo"
                    :key="'e_user_' + index"
                  ></van-tab>
                </van-tabs>
                <div class="flex align-center padding-sm padding-top">
                  <div class="point bg-red margin-right-xs"></div>
                  <div class="title text-df">考核指标</div>
                </div>
                <van-form>
                  <div
                    class="padding-sm flex align-center flex-direction justify-between"
                    v-for="(item, index) in dimensionZero"
                    :key="'type_one_a' + index"
                  >
                    <div
                      class="response flex align-center justify-between margin-bottom-xs"
                    >
                      <div class="flex justify-start flex-direction">
                        <div class="title text-df">{{ item.title }}</div>
                      </div>
                      <div class="flex align-center">
                        <van-rate allow-half :size="16" v-model="item.score" />
                        <div class="text-sm score-width">{{ item.score }}</div>
                      </div>
                    </div>
                    <div class="response text-sm text-left text-gray">
                      {{ item.intro }}
                    </div>
                    <van-field
                      class="border"
                      v-if="item.need_reason == 1"
                      v-model="item.reason"
                      :rules="[
                        {
                          required: true,
                          message: '请输入' + item.placeholder,
                        },
                      ]"
                      rows="2"
                      autosize
                      type="textarea"
                      :placeholder="item.placeholder"
                      @blur="saveEvaluateBySelf"
                    />
                  </div>
                </van-form>
                <div class="flex align-center padding-sm padding-top">
                  <div class="point bg-red margin-right-xs"></div>
                  <div class="title text-df">附加</div>
                </div>
                <van-form>
                  <div
                    class="padding-sm add"
                    v-for="(item, index) in dimensionOne"
                    :key="'type_one_b' + index"
                  >
                    <van-cell
                      v-if="item.need_choose == 1"
                      class="padding-lr-0"
                      :border="false"
                      :value="
                        item.choose_user == null ? '选择' : item.choose_user
                      "
                      is-link
                      :title="item.title"
                      @click="choosePerson(item)"
                    >
                    </van-cell>
                    <div
                      v-else
                      class="text-df response text-left padding-bottom-sm"
                    >
                      {{ item.title }}
                    </div>
                    <div class="response text-sm text-left text-gray">
                      {{ item.intro }}
                    </div>
                    <van-field
                      class="border"
                      v-if="item.need_reason == 1"
                      v-model="item.reason"
                      rows="2"
                      autosize
                      type="textarea"
                      :placeholder="item.placeholder"
                      :rules="[
                        {
                          required: true,
                          message: '请输入' + item.placeholder,
                        },
                      ]"
                      @blur="saveEvaluateBySelf"
                    />
                  </div>
                </van-form>
                <van-divider />
                <div>
                  <van-button
                    type="info"
                    size="normal"
                    @click="submitEvaluateEachByType"
                    >确认提交</van-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-lg"
          v-else-if="result.status == 1 && scoreStatus.status == 2"
        >
          您的自评已完成,等待其他人完成自评
        </div>
      </div>
    </div>
    <van-overlay :show="showPop" @click="showPopLay">
      <div class="poplay screen">
        <div class="flex justify-center close-btn">
          <van-icon name="clear" />
        </div>
        <div
          class="text-xl text-center padding-sm margin-bottom text-bold"
          v-if="result.stage == 0"
        >
          年中评价前置说明
        </div>
        <div
          class="text-xl text-center padding-sm margin-bottom text-bold"
          v-if="result.stage == 1"
        >
          年终评价前置说明
        </div>
        <van-field
          v-if="result.stage == 0"
          class="textarea"
          readonly
          v-model="organizationInfo.rule_one"
          autosize
          label=""
          type="textarea"
          placeholder=""
        />
        <van-field
          v-if="result.stage == 1"
          class="textarea"
          readonly
          v-model="organizationInfo.rule_two"
          autosize
          label=""
          type="textarea"
          placeholder=""
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  getResultDataAction,
  getUserScoreStatusAction,
  startUserEvaluteAction,
  getEvaluateSelfAction,
  submitEvaluateBySelfAction,
  getEvaluateUsersAction,
  getEvaluateEachByTypeAction,
  submitEvaluateEachByTypeAction,
  getOrganizationAction,
  // getEvaluateItemsAction,
} from "../api/evaluation.js";
import { getToken } from "../utils/auth"; // get token from cookie

import * as dd from "dingtalk-jsapi";
// import dd from 'dingtalk-jsapi';
import api from "../api/api";

import Vue from "vue";
import {
  Tab,
  Tabs,
  Image,
  Divider,
  Cell,
  CellGroup,
  Rate,
  Field,
  Button,
  Tag,
  Sticky,
  Form,
  Badge,
  Popup,
  Icon,
  Overlay,
} from "vant";
// import Button from "../components/basic-components/button/index.vue";
// import DropdownMenu from "../components/basic-components/dropdownMenu/index.vue";
// import Popover from "../components/basic-components/popover/index.vue";
// import BizCalendarChooseDateTime from "../components/jsapi/biz.calendar.chooseDateTime/index.vue";
// import BizContactComplexPicker from "../components/jsapi/biz.contact.complexPicker/index.vue";

Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Rate);
Vue.use(Field);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Sticky);
Vue.use(Form);
Vue.use(Badge);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Overlay);

export default {
  name: "Home",
  components: {
    // Button,
    // DropdownMenu,
    // Popover,
    // BizCalendarChooseDateTime,
    // BizContactComplexPicker,
  },
  created() {},
  mounted() {
    const hasToken = getToken();
    if (hasToken == "undefined" || hasToken == "") {
      this.$router.push("/login");
    }
    let userInfo = localStorage.getItem("userInfo");
    let showPop = localStorage.getItem("showPop");
    let booleanValue = showPop === "true";
    this.showPop = booleanValue;
    userInfo = JSON.parse(userInfo);
    this.userInfo = userInfo;
    // this.getEvaluateItems();
    this.getResultData();
    this.getOrganization();
    setTimeout(() => {
      let dimensionZero = localStorage.getItem("dimensionZero");
      let dimensionOne = localStorage.getItem("dimensionOne");
      if (dimensionZero != undefined) {
        dimensionZero = JSON.parse(dimensionZero);
        this.dimensionZero = dimensionZero;
      }
      if (dimensionOne != undefined) {
        dimensionOne = JSON.parse(dimensionOne);
        this.dimensionOne = dimensionOne;
      }
    }, 1000);
  },
  methods: {
    // getEvaluateItems() {
    //   let userInfo = this.userInfo;
    //   let userid = userInfo.userid;
    //   getEvaluateItemsAction({
    //     userid: userid,
    //     result_id: result_id,
    //   }).then((res) => {
    //     let errno = res.errno;
    //     if (errno == 0) {
    //     } else {
    //       that.$message({
    //         type: "error",
    //         message: res.errmsg,
    //       });
    //     }
    //   });
    // },
    showPopLay() {
      this.showPop = !this.showPop;
      localStorage.setItem("showPop", this.showPop);
    },
    onClick(index) {
      let oldIndex = this.oldIndex;
      if (index != oldIndex) {
        this.getEvaluateUsers();
        this.oldIndex = index;
        setTimeout(() => {
          let dimensionZero = localStorage.getItem("dimensionZero");
          let dimensionOne = localStorage.getItem("dimensionOne");
          if (dimensionZero != undefined) {
            dimensionZero = JSON.parse(dimensionZero);
            this.dimensionZero = dimensionZero;
          }
          if (dimensionOne != undefined) {
            dimensionOne = JSON.parse(dimensionOne);
            this.dimensionOne = dimensionOne;
          }
        }, 1000);
      }
    },
    changeEvaluateUser(name) {
      this.nowEvaluateUserId = name;
      this.getEvaluateEachByType();
    },
    saveEvaluateBySelf() {
      let that = this;
      let dimensionZero = that.dimensionZero;
      let dimensionOne = that.dimensionOne;
      dimensionZero = JSON.stringify(dimensionZero);
      dimensionOne = JSON.stringify(dimensionOne);
      localStorage.setItem("dimensionZero", dimensionZero);
      localStorage.setItem("dimensionOne", dimensionOne);
    },
    submitEvaluateBySelf() {
      let that = this;
      let dimensionZero = that.dimensionZero;
      let dimensionOne = that.dimensionOne;
      let dimensionData = dimensionZero.concat(dimensionOne);
      let myAssignUsers = that.myAssignUsers;
      let result_id = that.result_id;
      let hasNoScore = 0;
      let hasReason = 0;
      if (dimensionData.length > 0) {
        dimensionData.map((item) => {
          if (item.dimension == 0 && (item.score == null || item.score == 0)) {
            hasNoScore++;
          }
          let reason = item.reason;
          if (item.need_reason == 1) {
            if (!reason.trim()) {
              hasReason++;
            }
          }
        });
      }
      if (hasReason > 0) {
        that.$message({
          type: "error",
          message: "说明不能为空",
        });
        return false;
      }
      if (hasNoScore == 0) {
        submitEvaluateBySelfAction({
          dimensionData: dimensionData,
          myAssignUsers: myAssignUsers,
          result_id: result_id,
        }).then((res) => {
          let errno = res.errno;
          if (errno == 0) {
            that.$message({
              type: "success",
              message: "自我评价提交成功",
            });
            localStorage.removeItem("dimensionZero");
            localStorage.removeItem("dimensionOne");
            that.getUserScoreStatus();
          } else {
            that.$message({
              type: "error",
              message: res.errmsg,
            });
          }
        });
      } else {
        that.$message({
          type: "error",
          message: "评分不能为空",
        });
      }

      if (dimensionOne.length > 0) {
        for (const item of dimensionData) {
          let need_choose = item.need_choose;
          if (need_choose == 1 && item.choose_user_id == null) {
            that.$message({
              type: "error",
              message: "请选择同事!",
            });
            return false;
          }
        }
      }
    },
    deleteAssignUser(index) {
      let myAssignUsers = this.myAssignUsers;
      myAssignUsers.splice(index, 1);
    },
    choosePerson(item) {
      let userInfo = this.userInfo;
      let userid = userInfo.userid;
      let that = this;
      // dd.biz.contact.complexPicker({
      dd.chooseStaffForPC({
        max: 1,
        corpId: "ding2ed665dfe133e896",
        limitTips: "选择人数不能超过1个",
        title: "选择同事",
        showLabelPick: true,
        multiple: true,
        success: (res) => {
          if (res[0].emplId == userid) {
            that.$message({
              message: "不能选择自己",
              type: "error",
            });
            return false;
          }
          res = res.filter((item) => item.emplId !== item.choose_user_id);
          item.choose_user = res[0].name;
          item.choose_user_id = res[0].emplId;
        },
        fail: (err) => {
          const errcode = err.errorCode;
          if (errcode == -1) {
            this.$message({
              message: "已取消",
              type: "error",
            });
          } else if (errcode == 7) {
            this.$router.push("/login");
          }
        },
        complete: () => {},
      });
    },
    assignUsers() {
      // let href = location.href;
      let userInfo = this.userInfo;
      let userid = userInfo.userid;
      let myAssignUsers = this.myAssignUsers;
      let len = myAssignUsers.length;
      if (len > 2) {
        this.$message({
          message: "选择人数不能超过3个",
          type: "error",
        });
        return false;
      }

      // dd.biz.contact.complexPicker({
      dd.chooseStaffForPC({
        max: 3,
        corpId: "ding2ed665dfe133e896",
        limitTips: "选择人数不能超过3个",
        title: "邀请同事评价",
        showLabelPick: true,
        multiple: true,
        success: (res) => {
          // const { emplId, name, avatar } = res;
          res = res.filter((item) => item.emplId !== userid);
          let mergedArray;
          if (myAssignUsers != []) {
            let oldArrIds = new Set(myAssignUsers.map((item) => item.emplId));
            // 过滤掉 array 中与 oldArr 中 id 相等的项
            res = res.filter((item) => !oldArrIds.has(item.emplId));
            // 添加 oldArr 中不重复的项到 array 中
            mergedArray = res.concat(
              myAssignUsers.filter(
                (item) => !res.some((aItem) => aItem.emplId === item.userid)
              )
            );
          }
          let newInviteUsers = mergedArray;
          if (newInviteUsers.length > 3) {
            this.$message({
              message: "选择人数不能超过3个",
              type: "error",
            });
            return false;
          }
          this.myAssignUsers = newInviteUsers;
        },
        fail: (err) => {
          const errcode = err.errorCode;
          if (errcode == -1) {
            this.$message({
              message: "已取消",
              type: "error",
            });
          } else if (errcode == 7) {
            this.$router.push("/login");
          }
        },
        complete: () => {},
      });
    },
    getStatusText(status) {
      let statusText = "";
      let textColor = "";
      if (status == 0) {
        statusText = "未开始";
        textColor = "gray";
      }
      if (status == 1) {
        statusText = "自评阶段";
        textColor = "red";
      }
      if (status == 2) {
        statusText = "互评阶段";
        textColor = "red";
      }
      if (status > 2) {
        statusText = "已完成";
        textColor = "green";
      }
      return {
        statusText: statusText,
        textColor: textColor,
      };
    },
    getResultData() {
      let that = this;
      getResultDataAction().then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.result = data;
          that.result_id = data.id;
          that.getUserScoreStatus();
        }
      });
    },
    getEvaluateUsers() {
      let that = this;
      let result_id = that.result_id;
      let userInfo = that.userInfo;
      let userid = userInfo.userid;
      let active = that.active;
      // 这里的active = 0 时,是互评,包含了指定和邀请,如果是1时,则是评价上级管理者
      // 先去取人过来
      getEvaluateUsersAction({
        result_id: result_id,
        userid: userid,
        active: active,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.evaluateUsersDone = data.filter((item) => item.status == 2);
          that.evaluateUsersToDo = data.filter((item) => item.status == 0);
          let evaluateUsersToDo = that.evaluateUsersToDo;
          that.nowEvaluateUserId = evaluateUsersToDo[0].id;
          that.getEvaluateEachByType();
        }
      });
    },
    submitEvaluateEachByType() {
      let that = this;
      let nowEvaluateUserId = that.nowEvaluateUserId;
      let dimensionZero = that.dimensionZero;
      let dimensionOne = that.dimensionOne;
      let dimensionData = dimensionZero.concat(dimensionOne);
      let result_id = that.result_id;
      let hasNoScore = 0;
      let hasReason = 0;
      if (dimensionData.length > 0) {
        dimensionData.map((item) => {
          if (item.dimension == 0 && (item.score == null || item.score == 0)) {
            hasNoScore++;
          }
          let reason = item.reason;
          if (item.need_reason == 1) {
            if (!reason.trim()) {
              hasReason++;
            }
          }
        });
      }
      if (hasReason > 0) {
        that.$message({
          type: "error",
          message: "说明不能为空",
        });
        return false;
      }
      if (hasNoScore == 0) {
        submitEvaluateEachByTypeAction({
          evaluate_user_id: nowEvaluateUserId,
          dimensionData: dimensionData,
          result_id: result_id,
        }).then((res) => {
          let errno = res.errno;
          if (errno == 0) {
            that.$message({
              type: "success",
              message: "评价提交成功",
            });
            localStorage.removeItem("dimensionZero");
            localStorage.removeItem("dimensionOne");
            that.getEvaluateUsers();
          } else {
            that.$message({
              type: "error",
              message: res.errmsg,
            });
          }
        });
      } else {
        that.$message({
          type: "error",
          message: "评分不能为空",
        });
      }
    },
    getEvaluateEachByType() {
      let that = this;
      let result_id = that.result_id;
      let active = that.active;
      let nowEvaluateUserId = that.nowEvaluateUserId;
      that.dimensionZero = [];
      that.dimensionOne = [];
      // let that = this;
      getEvaluateEachByTypeAction({
        active: active,
        result_id: result_id,
        id: nowEvaluateUserId,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.dimensionZero = data.filter((item) => item.dimension == 0);
          that.dimensionOne = data.filter((item) => item.dimension == 1);
        }
      });
    },
    getUserScoreStatus() {
      let that = this;
      let result_id = that.result_id;
      getUserScoreStatusAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.scoreStatus = data;
          if (data.status == 1) {
            that.getEvaluateSelf();
          } else if (data.status == 2) {
            that.getEvaluateUsers();
          }
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    goHome() {
      this.$router.push("/login");
    },
    getEvaluateSelf() {
      let that = this;
      let result_id = that.result_id;
      getEvaluateSelfAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          const evaluate_item = data.evaluate_item;
          const invite_user = data.invite_user;
          that.dimensionZero = evaluate_item.filter(
            (item) => item.dimension == 0
          );
          that.dimensionOne = evaluate_item.filter(
            (item) => item.dimension == 1
          );
          that.invite_user = invite_user;
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    startUserEvalute() {
      let that = this;
      let result_id = that.result_id;
      startUserEvaluteAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        if (errno == 0) {
          that.scoreStatus = {
            status: 1,
          };
          that.getEvaluateSelf();
        } else if (errno == 102) {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
          that.scoreStatus = {
            status: 1,
          };
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    getOrganization() {
      let that = this;
      getOrganizationAction().then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.organizationInfo = data;
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
  },
  data() {
    return {
      corpId: api.corpId,
      userInfo: {},
      active: 0,
      result: {},
      scoreStatus: {
        status: 9,
      },
      result_id: 0,
      evaluateItems: [],
      dimensionZero: [],
      dimensionOne: [],
      invite_user: 0,
      myAssignUsers: [],
      evaluateUsersDone: [],
      evaluateUsersToDo: [],
      nowEvaluateUserId: 0,
      oldIndex: 0,
      showPop: true,
      organizationInfo: {},
    };
  },
};
</script>

<style scoped lang="scss">
.score-width {
  width: 20px;
}
.screen {
  max-width: 600px;
  margin: auto;
}
.border {
  margin-top: 8px;
  border: 1px solid #d4d4d4;
}
::v-deep .van-cell__title {
  display: flex;
  justify-content: flex-start;
}
.add {
  ::v-deep .van-cell {
    padding-top: 0;
  }
}
.margin-bottom-tab {
  margin-bottom: 100px;
}
.textarea {
  border: none;
  background: #f8f8f8;
}

.self {
  background: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
}

.poplay {
  background: #fff;
  margin-top: 40px;
  padding: 30px;
  border-radius: 4px;
  position: relative;
}
.close-btn {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 20px;
}
</style>